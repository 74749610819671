import { useState, useEffect } from 'react';
import { onAuthStateChanged, getAuth } from 'firebase/auth';
import { useSelector } from 'react-redux';
import { NotificationManager } from 'react-notifications';
import { clearIdentity } from './heap';


const authorizedRoles = [
  'ADMIN',
  'SUPER_ADMIN'
]


const useAuth = () => {
  const [currentUser, setCurrentUser] = useState(null);
  const [currentUserData, setCurrentUserData] = useState({});
  const [claims, setClaims] = useState(null);
  const [isLoading, setIsLoading] = useState(true); // Indicates that we're waiting for the auth state to be resolved

  const verifyAdmin = async () => {
    let claims = (await getAuth().currentUser?.getIdTokenResult())?.claims;
    if (claims['override_claims']) claims = {
      ...claims['override_claims'],
      reset_override: true
    };
    
    //console.log(claims, getAuth().currentUser)
    if (!isLoading && !!claims && !authorizedRoles.includes(claims?.role)) {
      setCurrentUser(false);
      await getAuth().signOut();
      NotificationManager.error(`You're not authorized to access this page. Please contact support if you have any inquiries.`)
    } else {
      setClaims(claims);
    }
  }

  useEffect(() => {
    if (currentUser) verifyAdmin()
  }, [currentUser])

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(getAuth(), (user) => {
      setCurrentUser(!!user);
      setCurrentUserData(user)
      if (!user) clearIdentity()
      setIsLoading(false); // Auth state is resolved, update loading state
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);

  return { currentUser, isLoading, claims, currentUserData };
};

export default useAuth;