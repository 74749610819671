import React from 'react';
import styled from 'styled-components';
import Select from 'react-dropdown-select';

const DropdownArea = styled(Select)`
  height: 20px !important;
  outline: none;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  border: 1px solid rgba(0,0,0,0.08) !important;
  border-radius: 8px !important;
  outline: none;

  * {
    font-family: 'Satoshi-Medium' !important;
    font-size: 14px !important;
    color: rgba(5, 31, 115, 1);
  }

  input {
    margin:-5px;
  }
`;


export const Dropdown = ({ options, value, setValue }) => {

    return <DropdownArea 
        onChange={(e) => {
            //console.log('e', e)
            setValue(e)
        }} 
        searchable={false}
        options={options} 
        values={Array.isArray(value) ? value : [value]}/>

}