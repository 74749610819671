
export const initializeHeap = () => {
    if (process.env.REACT_APP_HEAP_ID && window.heap) {
        window.heap.load(process.env.REACT_APP_HEAP_ID);
        window.heap.addEventProperties({ 'Admin UI': true });
    }
}

export const identifyBrand = (brand) => {
    if (process.env.REACT_APP_HEAP_ID && window.heap) {
        window.heap.addEventProperties({ 'Brand': brand });
    }
}

export const identify = (email, userId,  brand) => {
    if (process.env.REACT_APP_HEAP_ID && window.heap) {
        window.heap.identify(email);
        window.heap.addUserProperties({ 
            email, 
            "Database Identifier": userId,
            "Brand Admin": brand
        })
    }
}

export const clearIdentity = () => {
    if (process.env.REACT_APP_HEAP_ID && window.heap) {
        window.heap.resetIdentity();
    }
}