import React, { useState } from 'react';
import { LinkTertiary, Container, Input, Row, Column } from '../components/defaults';
import styled from 'styled-components';
import mainbg from '../images/main-bg.svg';
import sphere from '../images/sphere.svg';
import topCurve from '../images/top-curve.svg';
import backlinkArrow from '../images/arrow-left.svg';
import { getAuth, sendPasswordResetEmail } from 'firebase/auth';
import { AuthHeader } from '../components/auth-header';
import { 
    Contents, 
    ErrorComponent, 
    FormBox, 
    FormContents, 
    MainBg, 
    Sphere, 
    SubmitButton, 
    ThisTitle, 
    TopCurve 
} from '../components/auth-defaults';
import { useLocation } from 'react-router-dom';
import api from '../utils/api';
import { sendPasswordUpdateRequest } from '../utils/firebase';

const BacklinkArrow = styled.img`
    height: 15px;
    margin-right: 10px;
`;

const BackLink = styled(LinkTertiary)`
    margin-bottom: 40px;
    align-self: flex-start;
    margin-left: 0px;

    @media (max-width: 762px) {
        margin-bottom: 20px;
    }
`;

const Message = styled.div`
    background: #F4F3FE;
    color: #051F73;
    font-family: 'Satoshi-Medium';
    font-size: 18px;
    padding: 16px 24px;
    border-radius: 10px;
    margin-top: 20px;
    margin-bottom: -10px;
`;

export const ChangePassword = () => {

    const [password, setPassword] = useState('');
    const [passwordCopy, setPasswordCopy] = useState('');
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState('');
    
    const preValidate = () => {
        if (password !== passwordCopy) return setError(`Your passwords don't match`)
        if (password.length < 8) return setError(`Your password must be at least 8 characters`);
        return true
    }

    const location = useLocation();

    const submit = async () => {
        if (!preValidate()) return;
        setError(null);
        setLoading(true);
        const queryParams = new URLSearchParams(location.search);
        const token = queryParams.get('token');

        try {
            await sendPasswordUpdateRequest(token, password);
            setMessage(`Your password was successfully changed, please sign in to continue.`)
        } catch (err) {
            //console.log(err);
            setError('Something went wrong. Please contact support.');
        }
        setLoading(false);
    }


    return <Container style={{ background: '#4F59F0'}}>
        <AuthHeader dark={true} />
        <Contents> 
                <FormBox>
                    <FormContents>
                        <BackLink to={'/sign-in'} replace>
                            <BacklinkArrow src={backlinkArrow} />
                            Login
                        </BackLink>
                        <ThisTitle>Create your Password</ThisTitle>
                        {message && <Message>{message}</Message>}
                        {!message && 
                        <Column>
                        <Input 
                            value={password} 
                            onChange={(e) => setPassword(e.target.value)} 
                            label='Your new password' 
                            type='password'
                            style={{ marginTop: 32 }} />
                        <div style={{ flex: 1 }} />
                        <Input 
                            value={passwordCopy} 
                            onChange={(e) => setPasswordCopy(e.target.value)} 
                            label='Repeat your new password' 
                            type='password'
                            style={{ marginTop: 32 }} />
                        <div style={{ flex: 1 }} />
                        <ErrorComponent>{error}</ErrorComponent>

                        <Row style={{ alignItems: 'center', marginTop: 50 }}>
                            <SubmitButton loading={loading} disabled={loading || !!message ||  !password || !passwordCopy} onClick={submit}>Submit</SubmitButton>
                        </Row>
                        </Column>}
                    </FormContents>
                    <TopCurve src={topCurve}/>
                </FormBox>
            <MainBg src={mainbg}/>
            <Sphere src={sphere}/> 
        </Contents>
        
    </Container>     
}