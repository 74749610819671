import api from "./api";

export const fetchLaboratory = async () => {
    const result = (await api.get('/admin/laboratory')).data;
    return result.map((brand) => ({ label: brand.name, value: brand.id }));
}

export const fetchBrands = async () => {
    const result = (await api.get('/admin/brands')).data;
    return result.map((brand) => ({ label: brand.name, value: brand.id }));
}

export const fetchTests = async () => {
    const result = (await api.get('/admin/tests')).data;
    return result.map((test) => ({ label: test.name, value: test.id }));
}