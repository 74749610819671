import React, { useState } from 'react';
import { Modal } from './modal';
import styled from 'styled-components';
import { Column, Row } from './defaults';

const Box = styled.div`
    border-radius: 24px;
    background: white;
`;

const TopSection = styled.div`
    padding: 24px 40px;
    border-bottom: 1px solid rgba(219, 219, 219, 1);
`;

const FormSection = styled(Column)`
    padding: 32px 40px;
    width: 400px;
    margin-bottom: 10px;
`;

const Title = styled.div`
    font-size: 18px;
    color: rgba(5, 31, 115, 1);
`;

const Subtitle = styled.div`
    font-size: 16px;
    color: rgba(5, 31, 115, 1);
    margin-bottom: 0px;
`;  

const Text = styled.div`
    font-size: 16px;
    color:rgba(91, 99, 125, 1);
    margin-top: 10px;
`;

const Button = styled.div`
    color: rgba(79, 89, 240, 1);
    cursor: pointer;
    font-size: 16px;
`;

const PrimaryButton = styled.div`
    background: rgba(79, 89, 240, 1);
    color: white;
    font-size: 16px;
    border-radius: 100px;
    padding: 10px 15px;
    cursor: pointer;
    opacity: ${props => props.disabled ? 0.5 : 1};
    pointer-events: ${props => props.disabled ? 'none' : 'initial'};
`;

const Loading = styled.div`
`;

export default function DeleteAdmin({ 
    setVisible, 
    confirm,
    userId
}) {

    const [loading, setLoading] = useState(false);
    const onClick = async () => {
        setLoading(true);
        await new Promise((r) => setTimeout(r, 2000));
        await confirm(userId)
    }
    
    return <Modal onClickOutside={() => setVisible(null)}>
        <Box onClick={(e) => e.stopPropagation()}>
            
            <TopSection>
                <Title>Remove Admin</Title>
            </TopSection>
            <FormSection>
                <Subtitle>Are you sure?</Subtitle>
                <Text>By removing this admin, they will no longer have access to admin privileges, but account will be preserved as a regular user.</Text>
                <Row style={{ gap: 15, alignItems: 'center', justifyContent: 'flex-end', marginTop: 20 }}>
                    <PrimaryButton disabled={loading} onClick={onClick}>Confirm</PrimaryButton>
                    <Button onClick={() => setVisible(null)}>Cancel</Button>
                </Row>
            </FormSection>
            
        </Box>
    </Modal>

}