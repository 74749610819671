import { initializeApp } from "firebase/app";
import { getAuth, connectAuthEmulator, initializeAuth, signInWithCustomToken } from 'firebase/auth';
import api from "./api";
import passwordApi from "./password-api";


const firebaseConfig = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID,
}



const initialize = () => {
    //console.log('initializing with', firebaseConfig)
    const app = initializeApp(firebaseConfig);
    const auth = getAuth();
    if (process.env.REACT_APP_FIREBASE_AUTH_EMULATOR_HOST) {
        console.log('Connected to emulator')
        connectAuthEmulator(auth, process.env.REACT_APP_FIREBASE_AUTH_EMULATOR_HOST);
    };
    
    return app;
}

export const sendPasswordUpdateRequest = async (token, newPassword) => {
    const auth = getAuth();
    await signInWithCustomToken(auth, token);
    await api.post('/user/password', { newPassword });
    await auth.signOut();
}

export const sendPasswordCreateRequest = async (password) => {
    await passwordApi.post('/create-password', { password });
}

export default initialize;