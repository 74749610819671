import React, { useState } from 'react';
import { Modal } from './modal';
import styled from 'styled-components';
import { Column, Row } from './defaults';
import api from '../utils/api';
import { NotificationManager } from 'react-notifications';

const Box = styled.div`
    border-radius: 24px;
    background: white;
`;

const TopSection = styled.div`
    padding: 24px 40px;
    border-bottom: 1px solid rgba(219, 219, 219, 1);
`;

const FormSection = styled(Column)`
    padding: 32px 40px;
    gap: 20px;
    width: 400px;
    margin-bottom: 10px;
`;

const Title = styled.div`
    font-size: 18px;
    color: rgba(5, 31, 115, 1);
`;

const InputWrapper = styled(Column)`
    label {
        color: rgba(91, 99, 125, 1);
        font-size: 15px;
        margin-bottom: 10px;
    }
    input {
        background: rgba(249, 249, 249, 1);
        border-radius: 5px;
        border: 1px solid rgba(219, 219, 219, 1);
        padding: 15px 20px;
        font-size: 16px;
        outline: none;

        &:disabled {
            opacity: 0.5
        }
    } 
`;

const Input = ({ label, ...inputProps }) => {

    return <InputWrapper>
        <label>{label}</label>
        <input {...inputProps} />
    </InputWrapper>

}

const Button = styled.div`
    color: rgba(79, 89, 240, 1);
    cursor: pointer;
    font-size: 16px;
`;

const PrimaryButton = styled.div`
    background: rgba(79, 89, 240, 1);
    color: white;
    font-size: 16px;
    border-radius: 100px;
    padding: 10px 15px;
    cursor: pointer;
    opacity: ${props => props.disabled ? 0.5 : 1};
    pointer-events: ${props => props.disabled ? 'none' : 'initial'};
`;


export default function SendResult({ 
    setVisible, 
    barcode,
    onSuccess,
}) {

    const [loading, setLoading] = useState(false);
    const [biologicalAge, setBiologicalAge] = useState('');
    const [methylationLevel, setMethylationLevel] = useState('');

    const onClick = async () => {
        setLoading(true);
        await api.post('/admin/result', { barcode, biologicalAge, methylationLevel });
        NotificationManager.success('Result Sent!');
        setVisible(null);
        onSuccess()
    }
    
    const disabled = (!methylationLevel && !biologicalAge) || loading;

    return <Modal onClickOutside={() => setVisible(null)}>
        <Box onClick={(e) => e.stopPropagation()}>
            <TopSection>
                <Title>Send Result</Title>
            </TopSection>
            <FormSection>
                <Input label='Biological Age' value={biologicalAge} onChange={(e) => setBiologicalAge(e.target.value)} />
                <Input label='Methylation Level' value={methylationLevel} onChange={(e) => setMethylationLevel(e.target.value)} />
                <Row style={{ gap: 15, alignItems: 'center', justifyContent: 'flex-end', marginTop: 20 }}>
                    <Button onClick={() => setVisible(null)}>Cancel</Button>
                    <PrimaryButton disabled={disabled} onClick={onClick}>Confirm</PrimaryButton>
                </Row>
            </FormSection>
        </Box>
    </Modal>

}