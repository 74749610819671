import React, { useRef, useState } from 'react';
import { LinkTertiary, Container, Input, Row } from '../components/defaults';
import sphere from '../images/sphere.svg';
import { getAuth, signInWithEmailAndPassword, browserLocalPersistence, setPersistence } from 'firebase/auth';
import { AuthHeader } from '../components/auth-header';
import { 
    Contents, 
    ErrorComponent, 
    FormBox, 
    FormContents, 
    Sphere, 
    SubmitButton, 
    ThisSubtitle, 
    ThisTitle, 
} from '../components/auth-defaults';


export const SignIn = () => {

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const pwRef = useRef();

    const submit = async () => {
        if (loading || !email || !password) return;
        setLoading(true)
        setError('');
        try {
            //console.log(getAuth())
            await setPersistence(getAuth(), browserLocalPersistence);
            await signInWithEmailAndPassword(getAuth(), email, password);
        } catch (err) {
            if (err.message === 'Firebase: Error (auth/user-not-found).') setError('There was an error, please try another email or password.')
            else if (err.message === 'INVALID_LOGIN_CREDENTIALS') setError('There was an error, please try another email or password.')
            else setError('There was an error, please try again or contact support.')
        }
        setLoading(false);
    }

    return <Container style={{ background: '#4F59F0', minHeight: '80vh' }}>
        <AuthHeader dark={true} />
        <Contents> 
        <FormBox>
                    <FormContents>
                        <ThisTitle>Admin Panel</ThisTitle>
                        <ThisSubtitle>Login to your account below</ThisSubtitle>
                        <Input 
                            nextRef={pwRef}
                            value={email} 
                            onChange={(e) => setEmail(e.target.value)} 
                            label='Email' 
                            type='email'
                            style={{ marginTop:32, marginBottom: 32}} />
                        <Input 
                            value={password} 
                            type='password'
                            ref={pwRef}
                            submit={submit}
                            onChange={(e) => setPassword(e.target.value)} 
                            label='Password'  />
                        <ErrorComponent>{error}</ErrorComponent>
                        <div style={{ flex: 1 }} />
                        <Row style={{ marginTop: 10, alignItems: 'center' }}>
                            <SubmitButton loading={loading} disabled={loading || !email || !password} onClick={submit}>Submit</SubmitButton>
                            <LinkTertiary to='/sign-in/recover-pw'>Forgot Password?</LinkTertiary>
                        </Row>
                    </FormContents>
                </FormBox>
            <Sphere src={sphere}/> 
        </Contents>
        
    </Container>    
}