import axios from 'axios';

const baseURL = process.env.REACT_APP_API_URL ?
process.env.REACT_APP_API_URL :
process.env.REACT_APP_API_HOST ? 
`http://${process.env.REACT_APP_API_HOST}:${process.env.REACT_APP_API_PORT}`:
`http://localhost:${process.env.REACT_APP_API_PORT}`



const passwordApi = axios.create({ baseURL });

passwordApi.interceptors.request.use(
    async (config) => {
        const urlParams = new URLSearchParams(window.location.search);
        const token = urlParams.get('token')
        console.log(token)
        config.headers['Content-Type'] = 'application/json'
        config.headers.Authorization = `Bearer ${token}`;
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export default passwordApi;