/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Column, Row } from './defaults';
import loadingIcon from '../images/3-dots-move-purple.svg'
import api from '../utils/api';
import { NotificationManager } from 'react-notifications';
import { forwardRef } from 'react';


const FormSection = styled(Row)`
align-self: flex-start;
gap: 10px;
`;

const InputWrapper = styled(Row)`
    background: rgba(249, 249, 249, 1);
    border-radius: 5px;
    border: 1px solid rgba(219, 219, 219, 1);
    padding: 5px 8px;
    width: 250px;

    input {
        flex: 1;
        background: transparent;
        font-size: 14px;
        outline: none;
        border: none;
        font-family: 'Satoshi-Medium';

        &:disabled {
            opacity: 0.8
        }
    } 
`;

const Input = forwardRef(({ manualInput, scan, loading, label, value, ...inputProps }, ref) => {
    
    const disabled = !value;

    return  <InputWrapper>
        <input ref={ref} disabled={!manualInput} value={value} {...inputProps} />
        <SecondaryButton onClick={scan} disabled={disabled}>
            {loading ? <img src={loadingIcon} alt='loading'/> : 'Process'} 
        </SecondaryButton>
    </InputWrapper>
})

const PrimaryButton = styled(Column)`
    background: ${props => props.manualInput ? 'rgba(79, 89, 240, 1)' : 'white'};
    color: ${props => props.manualInput ? 'white' : 'rgba(79, 89, 240, 1)'};
    font-size: 14px;
    border-radius: 5px;
    padding: 10px 15px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    white-space: nowrap;
    border: ${props => props.manualInput ? '1px solid white' : '1px solid rgba(79, 89, 240, 1)'};
    opacity: ${props => props.disabled ? 0.5 : 1};
    pointer-events: ${props => props.disabled ? 'none' : 'initial'};
`;

const SecondaryButton = styled(Column)`
    background: transparent;;
    color: white;
    font-size: 13px;
    border-radius: 2px;
    color: rgba(79, 89, 240, 1);
    border: 1px solid rgba(79, 89, 240, 1);
    padding: 5px 10px;
    height: 20px;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    opacity: ${props => props.disabled ? 0.5 : 1};
    pointer-events: ${props => props.disabled ? 'none' : 'initial'};
`;


export default function Scan({ 
    onSuccess,
    setKitNoteId,
    barcodeInputRef
}) {
    const [manualInput, setManualInput] = useState(true);
    const [text, setText] = useState('');
    const [loading, setLoading] = useState(false);

    const scan = async () => {
        setLoading(true)
        try {
            const result = await api.post('/admin/scan', { barcode: text })
            const prevStatus = result.data.kit_previous_status
            if (prevStatus === 'UNASSIGNED') setKitNoteId(text)
            NotificationManager.success(
                prevStatus === 'UNASSIGNED' ?
                'Code scanned successfully. Please add further notes, as the kit was not registered.' :
                'Code scanned successfully.');

            setText('');
            onSuccess()
        } catch (err) {
            NotificationManager.error('Code not found.')
        }
        setLoading(false)
    }
    
    useEffect(() => {
        const handleBarcodeInput = (event) => {
            if (event.key === "Enter") {
                const scannedData = event.target.value;
                setText(scannedData)
                scan()
            }
        }

        setText('')
        if (!manualInput) {
            window.addEventListener('keypress', handleBarcodeInput);
        } else {
            window.removeEventListener('keypress', handleBarcodeInput);
        }
        return () => {
            window.removeEventListener('keypress', handleBarcodeInput);
        };
    }, [manualInput]);

    const onInputPress = (event) => {
        if (event.key === "Enter" && manualInput && !!text) scan();
    }

    return <FormSection>
            <PrimaryButton manualInput={manualInput} onClick={() => setManualInput((v) => !v)}>
                {manualInput ? 'Start Scanning' : 'Input manually'}
            </PrimaryButton>
        <Input 
        ref={barcodeInputRef}
        scan={scan}
        loading={loading} 
        value={text} 
        manualInput={manualInput}
        onKeyPress={onInputPress}
        onChange={(e) => setText(e.target.value)}
        placeholder={manualInput ? 'Type in a code' : 'Scan a code'} />
       
    </FormSection>


}