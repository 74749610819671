/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Button, Column, FlexRow, Row } from "../components/defaults";
import edit from '../images/edit-purple.svg';
import trash from '../images/trash.svg';
import api from '../utils/api';
import EditAdmin from "../components/edit-admin";
import loadingIcon from '../images/3-dots-move-purple.svg'
import { NotificationManager } from "react-notifications";
import DeleteAdmin from "../components/delete-admin";
const SELECTED_COLOR = 'rgba(79, 89, 240, 1)';


const Contents = styled(Column)`
    padding: 30px;
`;

const TopTitle = styled.div`
    color: ${SELECTED_COLOR};
    font-size: 31px;
    margin-bottom: 23px;
    font-family: 'Satoshi-Medium'
`;

const ButtonFilled = styled(Button)`
    border-radius: 100px;
    white-space: nowrap;
    padding: 10px 25px;
    height: 45px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 6px;
    color: rgba(79, 89, 240, 1);
    background: rgba(245, 246, 250, 1);
    opacity: ${ props => props.disabled ? 0.5 : 1};
    pointer-events: ${ props => props.disabled ? 'none' : 'initial'};
    
`;

const NoUsers = styled(Column)`
    height: 400px;
    border: 1px solid rgba(231, 231, 233, 1);
    align-items: center;
    justify-content: center;
    border-radius: 4px;

    h4 {
        font-size: 18px;
        margin: 0px;
        margin-bottom: 5px;
        color: rgba(5, 31, 115, 1);
    }

    div {
        font-size: 16px;
        color: rgba(91, 99, 125, 1);
    }
`;  

const UserButtons = ({ deleteUser, startEdit, setEditModalVisible, ...props }) => {
    return <Row style={{ gap: 10 }}>
        <ButtonFilled onClick={() => setEditModalVisible(props)}>
            <div>Edit</div>
            <img src={edit} alt='Edit' />
        </ButtonFilled>
        <ButtonFilled onClick={deleteUser} style={{ padding: 17}}>
            <img src={trash} alt='Delete user' />
        </ButtonFilled>
    </Row>
}

const Title = styled.div`
    color: rgba(5, 31, 115, 1);
    margin-bottom: 3px;
    cursor: pointer;
`;

const Text = styled.div`
    color: rgba(91, 99, 125, 1);
`;

const UserBoxWrapper = styled(FlexRow)`
    justify-content: space-between;
    align-items: center;
    padding: 22px 32px;
    border: 1px solid rgba(231, 231, 233, 1);
    border-radius: 9px;
    margin-bottom: 10px;
`;

const UserBox = ({ 
    first_name, 
    contact, 
    last_name, 
    brand, 
    id,
    ...props
}) => {
    
    return <UserBoxWrapper>
        <Column>
            <Title>{first_name} {last_name}</Title>
            <Text>{contact?.email}</Text>
        </Column>
        <Text>{brand?.name}</Text>
        <UserButtons {...{first_name, contact, last_name, brand, id, ...props}} id={id}/>
    </UserBoxWrapper>
}

export const ManageUsers = () => {

    const [loading, setLoading] = useState(false);
    const [currentEdit, setCurrentEdit] = useState(null);
    const [currentDelete, setCurrentDelete] = useState(null);
    const [admins, setAdmins] = useState([]);
    const [addModalVisible, setAddModalVisible] = useState(false);

    const setEditModalVisible = (values) => {
        if (values) setCurrentEdit(values)
        else setCurrentEdit(null);
    }

    const editModalVisible = !!currentEdit;

    const setDeleteModalVisible = (values) => {
        if (values) setCurrentDelete(values)
        else setCurrentDelete(null)
    }

    const deleteModalVisible = !!currentDelete;
    
    useEffect(() => {
        fetchAdmins();
    }, [])
    

    const fetchAdmins = async () => {
        setLoading(true)
        const result = (await api.get('/admin/users')).data
        setAdmins(result)
        setLoading(false);
    }

    const edit = async (values) => {
        await api.patch('/admin/users', values);
        setEditModalVisible(false);
        NotificationManager.success('User successfully updated!')
        await fetchAdmins()
    }

    const deleteUser = async () => {
        await api.delete('/admin/users', { data: { user_id: currentDelete } })
        setDeleteModalVisible(false)
        NotificationManager.success('User downgraded to regular user.');
        await fetchAdmins()
    }

    const addAdmin = async (data) => {
        await api.post('/admin/users', data);
        setAddModalVisible(false);
        NotificationManager.success(`Admin successfully created. We sent an email further instructions to ${data.email}.`);
        await fetchAdmins();
    }

    return <Contents>
        {addModalVisible && <EditAdmin
            modalTitle="Add Admin"
            setVisible={setAddModalVisible}
            confirm={addAdmin}
        />}
        {deleteModalVisible && <DeleteAdmin
            setVisible={setDeleteModalVisible}
            userId={currentDelete}
            confirm={deleteUser}
            />
        }
        {editModalVisible && <EditAdmin 
            modalTitle="Edit Admin"
            setVisible={setEditModalVisible}
            defaults={currentEdit} 
            confirm={edit}
            emailDisabled
        />}
        <Row style={{ marginBottom: 20 }}>
            <TopTitle>Manage Access</TopTitle>
            <div style={{ flex: 1 }} />
            <ButtonFilled onClick={() => setAddModalVisible(true)} style={{ color: 'white', background: 'rgba(79, 89, 240, 1)'}}>
                Add Admin
            </ButtonFilled>
        </Row>
        {loading && <Column style={{ alignItems: 'center', justifyContent: 'center', height: '30vh'}}>
            <img src={loadingIcon} alt='loading'/>
        </Column>}
        {!loading && !admins?.length && <NoUsers>
            <h4>Nothing to see here yet!</h4>
            <div>You haven’t added any admins.</div>
        </NoUsers>}
        {!loading && admins?.map((admin) => <UserBox 
            setEditModalVisible={setEditModalVisible}
            editModalVisible={editModalVisible}
            deleteUser={() => setCurrentDelete(admin.id)}
            {...admin}/>)}
    </Contents>

}