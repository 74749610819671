/* eslint-disable no-restricted-globals */
import React from 'react';
import { Column } from '../components/defaults';
import styled from 'styled-components';
import loadingIcon from '../images/grid.svg';

const LoadingMaskWrapper = styled(Column)`
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    z-index: 2;
    background-color: rgba(0,0,0,0.5);
    align-items: center;
    justify-content: center;
    z-index: 10000;
`;

const LoadingIcon = styled.img`
    height: 40px;
`;



export const LoadingMask = () => {
    return <LoadingMaskWrapper>
        <LoadingIcon src={loadingIcon} />
    </LoadingMaskWrapper>
}