import React from 'react';
import styled from 'styled-components';
import { Row } from './defaults';
import back from '../images/back.svg';
import next from '../images/next.svg';

const PaginatorWrapper = styled(Row)`
    align-items: center;
`;

const PointerButton = styled(Row)`
    align-items: center;
    gap: 10px;
    color: rgba(5, 31, 115, 1);
    cursor: pointer;
    pointer-events: ${props => props.disabled ? 'none' : 'initial'};
    opacity: ${props => props.disabled ? 0.5 : 1}
`;

const Icon = styled.img`
`;

export const Paginator = ({ showPrev, showNext, skip, nextDisabled }) => {

    return <PaginatorWrapper>
        <PointerButton disabled={skip === 0} onClick={() => showPrev()}>
            <Icon src={back} alt='back' />
            Previous
        </PointerButton>
        <div style={{ width: 30 }} />
        <PointerButton disabled={nextDisabled} onClick={() => showNext()}>
            Next
            <Icon src={next} alt='back' />
        </PointerButton>
    </PaginatorWrapper>

}