/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import ContentLoader from 'react-content-loader'
import { Button, Dropdown, FlexRow, Row } from "../components/defaults";

import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { COUNTRIES } from "../files/countries";
import api from "../utils/api";
import { NotificationManager } from "react-notifications";
import moment from 'moment'

const SELECTED_COLOR = 'rgba(79, 89, 240, 1)';

const Loader = () => <ContentLoader viewBox="0 0 150 30">
    <rect rx="4" ry="4" width="150px" height="30px" />
</ContentLoader>

const Contents = styled.div`
    padding: 30px;
    padding-bottom: 80px;
`;

const TopTitle = styled.div`
    color: ${SELECTED_COLOR};
    font-size: 31px;
    margin-bottom: 23px;
    font-family: 'Satoshi-Medium'
`;

const ItemWrapper = styled(FlexRow)`
    border-bottom: 1px solid rgba(231, 231, 233, 0.2);
    border-top: 1px solid rgba(231, 231, 233, 0.8);
    height: 72px;
        align-items: center;
`;

const ItemKey = styled.div`
    color: rgba(5, 31, 115, 1);
    font-family: 'Satoshi-Bold';
    flex: 0.5;
`;

const GoBack = styled.div`
    color: rgba(91, 99, 125, 1);
    font-size: 14px;
    cursor: pointer
`;

const Edit = styled.input`
    border: 1px solid rgba(219, 219, 219, 1);
    border-radius: 4px;
    padding: 11px 12px;
    font-size: 16px;
    flex: 1;
    font-family: 'Satoshi-Medium';
    outline: none;
    color: rgba(91, 99, 125, 1);
    

    &:focus {
        border: 1px solid rgba(5, 31, 115, 1);;
    }

    &::placeholder {
        color: rgba(91, 99, 125, 0.3);
    }
`;

function replaceNonNumbersAndSuffix(inputString, suffix) {
    const regex = new RegExp(`[^0-9.${suffix}]`, 'g');
    return inputString.replace(regex, '');
}

const ItemValue = ({ 
    type, 
    values, 
    label, 
    suffix, 
    setValues,
    placeholder,
    options,
    editDisabled,
    defaultValue,
}) => {

    const value =  values[label] && suffix ? values[label] + suffix : values[label];

    const inputRef = useRef(null);

    const update = (e) => {
        
        let newValue = Array.isArray(e) || typeof(e) === 'string' ? e : e?.target?.value


        newValue = typeof(newValue) === 'string' ? newValue.charAt(0).toUpperCase() + newValue.slice(1) : newValue


        if (newValue?.length && newValue[0]?.label) {
            newValue = e[0].label
        }
        
        if (suffix && ((newValue?.length || 0) < value?.length)) {
            const diff = newValue - value?.length;
            newValue = value?.slice(0, diff - suffix.length)
        }
        if (suffix && newValue) newValue = 
            replaceNonNumbersAndSuffix(newValue.replace(new RegExp(suffix, 'g'), '', ), suffix)

        
        
        
        setValues((v) => ({...v, [label]: newValue}))
    }

    useEffect(() => {
        if (defaultValue) update(defaultValue)
    }, [defaultValue])

    return options? <Dropdown 
    value={value}
    onChange={update}
    options={options} /> :
    <Edit
    disabled={editDisabled} 
    placeholder={placeholder} 
    ref={inputRef} 
    type={type || 'text'} 
    value={value} 
    onChange={update} /> 

}

const ItemRow = styled(Row)`
    flex: 1; 
    gap: 10px;
    
    &>* {
        flex: 1;
    }

    .react-dropdown-select-content {
        white-space: nowrap;
    }

    .react-dropdown-select-input {
        flex: 4;
    }

    .react-dropdown-select-dropdown-handle {
        flex: initial;
    }
`

const Item = ({ 
    name, 
    items,
    values,
    setValues,
    editMode,
    loading,
    disabled,
    editDisabled,
    defaultValue,
    ...props
}) => {

    const sharedProps = {
        values, setValues, name, editMode, loading
    }
    
    items = items?.length ? 
    items.map((i) => ({...sharedProps, ...i})) : 
    [{...sharedProps, ...props}]


    return <ItemWrapper>
        <ItemKey>{name}</ItemKey>
        <ItemRow>
            {items.map((i) => <ItemValue 
            editDisabled={editDisabled} 
            disabled={disabled} 
            defaultValue={defaultValue}
            {...sharedProps} 
            {...i} />)}
        </ItemRow>
    </ItemWrapper>
}


const SLEEP_HOURS = ['5 or less', '6', '7', '8', '9', '10+']
const YES_NO = ['Yes', 'No']
const DRINKS_PER_WEEK = ['0', '1-2', '3-5', '6-9', '10+']
const PHYSICAL_ACTIVITY_DAYS = ['0', '1', '2', '3', '4', '5', '6+']
const BIOLOGICAL_SEX_OPTIONS = ['Female', 'Male'];
const GENDER_OPTIONS = ['Male', 'Female', 'Non-Binary'];
const ETHNICITY_OPTIONS = ['White', 'Hispanic and Latino', 'Black / African American', 'Asian', 'American Indian or Alaska Native', 'Native Hawaiian or Other Pacific Islander', 'Other']
const CONDITION_OPTIONS = ['Excellent', 'Good', 'Fair', 'Poor']
const STRESS_OPTIONS = [ 'Not at all', 'Somewhat stressed', 'Really stressed', 'Extremely stressed' ]

const detailsKeys = [
    { label: 'email', name: 'Email*', editDisabled: true,  placeholder: 'mail@mail.com' },
    { label: 'registered_at', name: 'Sample collection Date', type: 'date', defaultValue: moment().format('YYYY-MM-DD') },
    { label: 'first_name', name: 'First Name*', placeholder: 'John' },
    { label: 'last_name', name: 'Last Name*', placeholder: 'Doe' },
    { label: 'dob', name: 'Date of Birth*', type: 'date' },
    { label: 'gender', name: 'Gender', options: GENDER_OPTIONS },
    { label: 'biological_sex', name: 'Biological Sex', options: BIOLOGICAL_SEX_OPTIONS},
    { label: 'country', name: 'Country', options: COUNTRIES.map((c) => c.name)},
    { label: 'ethnicity', name: 'Ethnicity', options: ETHNICITY_OPTIONS},
    { items: [
        { label: 'height_feet', name: 'Feet', number: true, suffix: ' Ft', placeholder: 'Feet'},
        { label: 'height_inches', name: 'Inches', number: true, suffix: ' In', placeholder: 'Inches'}
    ], name: 'Height', },
    { label: 'weight', name: 'Weight in pounds (lbs)', suffix: ' Lbs', placeholder: 'Pounds', number: true },
    { label: 'physical_activity_days', name: 'Physical activity days', placeholder: 'Days', options: PHYSICAL_ACTIVITY_DAYS},
    { label: 'sleep_hours', name: 'Sleep hours', options: SLEEP_HOURS},
    { label: 'alcohol_consumption', name: 'Alcoholic drinks per week', options: DRINKS_PER_WEEK},
    { label: 'trouble_sleeping', name: 'Do you have trouble sleeping?', options: YES_NO},
    { label: 'waking_up_condition', name: 'Do you wake up rested?', options: YES_NO},
    { name: 'Do you drink coffee?', label: 'coffee_consumption',options: YES_NO },
    { name: 'Do you smoke?', label: 'tobacco_consumption', options: YES_NO },
    { name: 'Have you been diagnosed with cancer?', label: 'has_cancer', options: YES_NO },
    { name: 'Have you been diagnosed with diabetes?', label: 'has_diabetes', options: YES_NO }, 
    { name: 'How would you rate your mental health?', label: 'mental_health', options: CONDITION_OPTIONS},
    { name: 'Are you satisfied with your social life?', label: 'social_life', options: YES_NO },
    { name: `How stressed are you?`, label: 'stress', options: STRESS_OPTIONS },
    { name: 'What medications are you taking?',label: 'medicine_consumption', placeholder: 'Medicine list' },
    { name: 'What supplements are you taking?', label: 'supplement_consumption', placeholder: 'Supplement List' },
    { name: 'How would you rate your overall health?', label: 'overall_health', options: CONDITION_OPTIONS}
]



const mapResultsBack = (data) =>  {
    return {
        biological_sex: data.biological_sex,
        gender: data.gender,
        ethnicity: data.ethnicity,
        country: data.country,
        date_of_birth: data.dob,
        first_name: data.first_name,
        last_name: data.last_name,
        email: data.email,
        weight: data.weight ? parseInt(data.weight) : null,
        height_feet: data.height_feet ? parseInt(data.height_feet) : null,
        height_inches: data.height_inches ? parseInt(data.height_inches) : null,
        biological_age: data.biological_age ? parseFloat(data.biological_age) : null,
        chronological_age: data.chronological_age ? parseFloat(data.chronological_age) : null,
        methylation_level: data.methylation_level ? parseFloat(data.methylation_level) : null,    
        scanned_at: data.scanned_at,
        barcode: data.barcode,
        registered_at: data.registered_at,

        physical_activity_days: data.physical_activity_days,
        sleep_hours: data.sleep_hours,
        alcohol_consumption: data.alcohol_consumption,
        trouble_sleeping: data.trouble_sleeping,
        waking_up_condition: data.waking_up_condition,
        coffee_consumption: data.coffee_consumption,
        tobacco_consumption: data.tobacco_consumption,
        has_cancer: data.has_cancer,
        has_diabetes: data.has_diabetes,
        mental_health: data.mental_health,
        social_life: data.social_life,
        stress: data.stress,
        medicine_consumption: data.medicine_consumption,
        supplement_consumption: data.supplement_consumption,
        overall_health: data.overall_health
    };

}

const BottomBar = styled(Row)`
    background: white;
    position: fixed;
    bottom: 0px;
    left: 0px;
    right: 0px;
    z-index: 100000;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    border-top: 1px solid rgba(0,0,0,.1);
`;

const requiredValues = [
    'first_name',
    'last_name',
    'email',
    'dob',
    'registered_at'
]

const Disclaimer = styled.div`
    color: gray;
    font-size: 13px;
`;

const Error = styled.div`
    margin-left: 10px;
    color: red;
    font-size: 13px;
    align-self: flex-start;
`;

export const AssignKit = () => {

    const [error, setError] = useState(null)
    const [values, setValues] = useState({});
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const params = useParams();
    const [searchParams] = useSearchParams()
    
    const sharedProps = {
        values,
        setValues,
        loading
    }

    const submit = async () => {
        setLoading(true)
        try {
            await api.post('/admin/kit/register', { 
                barcode: params.id,
                email: values.email,
                new_user: mapResultsBack(values)
            })
            navigate('/dashboard/overview?tab=unassigned');
            setTimeout(() => {
                NotificationManager.success(`Kit ${params.id} was sucessfully associated with ${values.email}`);
            }, 100)
        } catch (err) {
            setError('An error happened.')
        }
        setLoading(false)
    }

    useEffect(() => {
        if (searchParams.get('email')) {
            setValues((v) => ({...v, email: searchParams.get('email') }))
        }
    }, [searchParams])

    const submitDisabled = requiredValues.filter((v) => !values[v])?.length > 0;
    
    return <Contents>
        <GoBack onClick={() => navigate(-1)}>Go back</GoBack>
        <Row style={{ gap: 10, marginBottom: 10 }}>
            <TopTitle>Assign Kit to Patient - </TopTitle>
            <TopTitle><b>{params.id}</b></TopTitle>   
        </Row>
        { detailsKeys.map((k) => <Item {...sharedProps} {...k} data={values} />) }
        <BottomBar>
            <Row>
            <Disclaimer>* Required</Disclaimer>
            <Error>{error}</Error>
            </Row>
            <Button onClick={submit} disabled={submitDisabled || loading}>Submit</Button>
        </BottomBar>
    </Contents>

}