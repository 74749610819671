/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { Button, Row } from "../components/defaults";
import moment from 'moment'
import { RegisteredIcon } from "../images/registered";
import { UnassignedIcon } from "../images/unassigned";
import { InLabIcon } from "../images/in-lab";
import { ProcessedIcon } from "../images/processed";
import { FlaggedIcon } from "../images/flagged";
import api from "../utils/api";
import queryString from 'query-string';
import useAuth from "../utils/useAuth";
import { Search } from "../components/search";
import { Table, TableContext, TableContextWrapper } from "../components/table";
import { useLocation, useNavigate } from "react-router-dom";
import Notes from "../components/notes";
import Scan from "../components/scan";
import SendResult from "../components/send-result";
import { fetchBrands, fetchLaboratory, fetchTests } from "../utils/brand";
import { useRef } from "react";
import { AssignToPatientModal } from "../components/assign-kit-modal";

const SELECTED_COLOR = 'rgba(79, 89, 240, 1)';
const UNSELECTED_COLOR = 'rgba(91, 99, 125, 1)';

const statusBgColors = {
    'REGISTERED': 'rgba(255, 77, 109, 1)',
    'PROCESSED': 'rgba(79, 222, 119, 1)',
    'UNASSIGNED': 'rgba(255, 77, 109, 1)',
    'IN_LAB': '#FEAF1E',
    'FLAGGED': 'rgba(255, 77, 109, 1)',
}



const Contents = styled.div`
    padding: 30px;
`;

const TopTitle = styled.div`
    color: ${SELECTED_COLOR};
    font-size: 31px;
    margin-bottom: 23px;
    font-family: 'Satoshi-Medium'
`;


const TopRow = styled(Row)`

`;

const TabTitleWrapper = styled(Row)`
    padding:16px 22px;
    align-items: center;
    cursor: pointer;
    border-bottom: ${props => props.selected ? `1px solid ${SELECTED_COLOR}` : '1px solid transparent'};
`;

const TabTitleText = styled.div`
    font-size: 17px;
    margin-left: 8px;
    color: ${props => props.selected ?  SELECTED_COLOR : UNSELECTED_COLOR };
`;

const Count = styled.div`
    color: white;
    background: rgba(79, 89, 240, 1);
    font-size: 12px;
    border-radius: 4px;
    padding: 3px;
    width: 16px;
    height: 16px;
    text-align: center;
    margin-left: 10px;
`;

const TabTitle = ({ selected = false, count, Icon, title, onClick = () => {} }) => {
    return <TabTitleWrapper selected={selected} onClick={onClick}>
        <Icon fill={selected ? SELECTED_COLOR : UNSELECTED_COLOR} />
        <TabTitleText selected={selected}>{title}</TabTitleText>
        {count > 0 && <Count>{count}</Count>}
    </TabTitleWrapper>
}

const mapData = (data) => ({
    code: data.barcode,
    user_id: data.user_id,
    status: data.status,
    test: data.test_types[0]?.name,
    chronological_age: data?.chronological_age?.toFixed(2),
    biological_age: data.health_result?.biological_age?.toFixed(2),
    brand: data.brand?.name,
    laboratory: data.laboratory?.name,
    laboratory_number: data.laboratory_number,
    peer_biological_age_score: data.health_result?.peer_biological_age_score?.toFixed(2),
    methylation_level: data.health_result?.methylation_level?.toFixed(2),
    sample_date: data.registered_at ? moment(data.registered_at).format('MM-DD-YYYY') : '--',
    scanned_at: data.scanned_at ? moment(data.scanned_at).format('MM-DD-YYYY') : '--',
})



const PillWrapper = styled.div`
    padding: 10px 20px;
    color: white;
    background: ${props => props.background};
    border-radius: 100px;
`;

const PillButton = styled(PillWrapper)`
    background: rgba(245, 246, 250, 1);
    color: rgba(79, 89, 240, 1);
    cursor: pointer;
`;

const deconstructQueryObject = (query, prefix) => {
    return Object.entries(query).reduce((prev, [key, value]) => {
        if (!!value?.value) return {...prev, [`${prefix}${key}`]: value.value }
        else return {...prev, [`${prefix}${key}`]: value }
    }, {})
}

const selectedColumns = {
    'UNASSIGNED': ['code', 'status', 'test', 'brand', 'laboratory', 'commands'],
    'REGISTERED': ['code', 'status',  'test', 'brand', 'laboratory', 'commands'],
    'IN_LAB': ['code', 'status',  'test', 'brand', 'laboratory', 'commands', 'laboratory_number', 'chronological_age', 'sample_date'],
    'PROCESSED': ['code', 'status', 'test', 'brand', 'laboratory', 'commands', 'laboratory_number', 'chronological_age', 'sample_date', 'scanned_at', 'biological_age', 'methylation_level'],
    'FLAGGED': null
}

export const OverviewContents = () => {

    const [kitNoteId, setKitNoteId] = useState(null);
    const [kitResultId, setKitResultId] = useState(null);
    const [selectedKit, setSelectedKit] = useState(null);
    const [selectedTab, setSelectedTab] = useState('REGISTERED');
    const [data, setData] = useState([]);
    const [flaggedCount, setFlaggedCount] = useState([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate()
    const location = useLocation();


    const barcodeInputRef = useRef();

    const {
        query,
        setQuery,
        skip,
        limit,
        setSkip,
        fieldSort,
        fieldQuery
    } = useContext(TableContext)

    const InLabCommands = ({ id, ...props}) => {

        return <>
            <PillButton style={{ marginRight: 16 }} onClick={() => {
                setKitNoteId(id)
            }} >Notes</PillButton>
            {selectedTab === 'IN_LAB' && claims?.brand === 1 && props?.laboratory === 'TruMe Labs' && <PillButton onClick={() => setKitResultId(props.code)} style={{ 
                marginRight: 16,
            }}>Send Result</PillButton>}
            {props.user_id ? <PillButton onClick={() => navigate(`/dashboard/kit/${props.code}`)} style={{ 
                marginRight: 16,
                pointerEvents: props.user_id ? 'initial' : 'none',
                opacity: props.user_id ? 1 : 0.2
            }}>Patient File</PillButton> : <PillButton style={{ marginRight: 16 }} onClick={() => setSelectedKit(props.code)} >Assign to Patient</PillButton>}  
        </>
    }

    const UnassignedCommands = ({ id, ...props }) => {
        return <>
            <PillButton style={{ marginRight: 16 }} onClick={() => setSelectedKit(props.code)} >Assign to Patient</PillButton>
        </>
    }
    
    const columns = (id) => [
        { id: 'code', title: 'Code', disableQuery: true },
        { id: 'laboratory_number', title: 'Lab. Number' },
        { id: 'status', title: 'Status', disableQuery: true, disableSorting: true, formatter: (values) => <PillWrapper background={statusBgColors[values['status']]}>{values['status']}</PillWrapper> },
        { id: 'test', title: 'Test', fetchOptions: fetchTests, disableSorting: true },
        { id: 'chronological_age', title: 'Chron. Age', numberOnly: true },
        { id: 'biological_age', title: 'Biol. Age', numberOnly: true },
        { id: 'methylation_level', title: 'Methyl. Level', numberOnly: true  },
        { id: 'peer_biological_age_score', title: 'Peer. Score', numberOnly: true  },
        { id: 'sample_date', title: 'Sample Date', dateField: true},
        { id: 'brand', title: 'Brand', fetchOptions: fetchBrands, disableSorting: true },
        { id: 'laboratory', title: 'Laboratory', fetchOptions: fetchLaboratory, disableSorting: true },
        { id: 'scanned_at', dateField: true, title: 'Received Date'},
        { id: 'commands', title: '', formatter: (values) => selectedTab === 'UNASSIGNED' ?
        <UnassignedCommands {...{ id, ...values }} /> :
        <InLabCommands id={id} {...values} />}
    ]
    
    const { claims } = useAuth();

    useEffect(() => {
        const tab = new URLSearchParams(location.search).get('tab');
        setSelectedTab(tab.toLocaleUpperCase())
        setQuery('')
    }, [location.search])

    const sharedProps = (tabName) => ({
        onClick: () => {
            navigate(`/dashboard/overview?tab=${tabName.toLowerCase()}`)
        },
        selected: tabName === selectedTab,
    })

    const fetchKits = async () => {
        setLoading(true)

        const params = {
            status: selectedTab,
            ...(query && { query_barcode: query }),
            skip,
            limit: limit[0]?.value,
            ...(fieldQuery ? deconstructQueryObject(fieldQuery, 'query_') : {}),
            ...(fieldSort ? deconstructQueryObject(fieldSort, 'sort_') : {}),
        }

        const results = await api.get(`/admin/overview?${queryString.stringify(params)}`);
        console.log('re', results.data.results)
        setData(results.data?.results?.map(mapData));
        setFlaggedCount(results.data?.flagged_count)
        setLoading(false)
    };

    useEffect(() => {
        if (claims) fetchKits();
    }, [selectedTab, claims, limit, query, skip, fieldQuery, fieldSort])

    console.log('d', data)

    return <Contents>
        {kitResultId && <SendResult onSuccess={fetchKits} barcode={kitResultId} setVisible={setKitResultId}/>}
        <Row>
            <TopTitle>Overview</TopTitle>
            <div style={{ flex: 1 }} />
            
        </Row>
        {kitNoteId && <Notes barcodeInputRef={barcodeInputRef} id={kitNoteId} setVisible={setKitNoteId} />}
        <TopRow>
            <TabTitle {...sharedProps('UNASSIGNED')} Icon={UnassignedIcon} title='Unassigned'/>
            <TabTitle {...sharedProps('REGISTERED')} Icon={RegisteredIcon} title='Registered'/>
            <TabTitle {...sharedProps('IN_LAB')} Icon={InLabIcon} title='In lab'/>
            <TabTitle {...sharedProps('PROCESSED')} Icon={ProcessedIcon} title='Processed' />
            <TabTitle {...sharedProps('FLAGGED')} Icon={FlaggedIcon} title='Flagged' count={flaggedCount} />
            <div style={{ flex: 1 }} />
            <Search setSkip={setSkip} setQuery={setQuery}/>
        </TopRow>
        <Table data={data} selectedColumns={selectedColumns[selectedTab]} columns={columns} loading={loading} />
        {!!selectedKit && <AssignToPatientModal fetchKits={fetchKits} barcode={selectedKit} setVisible={() => setSelectedKit(null)}  />}
    </Contents>

}

export const Overview = () => {

    return <div> 
        <TableContextWrapper>
            <OverviewContents />
        </TableContextWrapper>  
    </div>

}