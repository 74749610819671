export const alphanumericSort = (key = 'lab_number') => (a, b) => {
    const regex = /(\d+)|(\D+)/g;
    //console.log(a, key)

    const aParts = a[key].toString().match(regex);
    const bParts = b[key].toString().match(regex);

    for (let i = 0; i < Math.max(aParts.length, bParts.length); i++) {
        const aPart = aParts[i] || "";
        const bPart = bParts[i] || "";

        // If both parts are numeric, compare as numbers
        if (!isNaN(aPart) && !isNaN(bPart)) {
            const numA = parseInt(aPart, 10);
            const numB = parseInt(bPart, 10);
            if (numA !== numB) {
                return numA - numB;
            }
        } else {
            // Compare as strings
            if (aPart !== bPart) {
                return aPart.localeCompare(bPart);
            }
        }
    }

    return 0;
}
