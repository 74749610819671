import { createSlice } from '@reduxjs/toolkit'
import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../utils/api';

export const fetchUserData = createAsyncThunk(
    'user/load',
    async () => {
        const response = await api.get('/user');
        return response.data;
    }
);

const initialState = {
  loading: false,
  userData: {},
}

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        clear: () => {
            return initialState
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchUserData.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(fetchUserData.fulfilled, (state, action) => {
            state.loading = false;
            state.userData = action.payload;
        });
        builder.addCase(fetchUserData.rejected, (state) => {
            state.loading = false;
        })
    }
})

export const { clear } = userSlice.actions;

export default userSlice.reducer;
  