/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-restricted-globals */
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import ContentLoader from 'react-content-loader'
import { Button, Dropdown, FlexRow, Row } from "../components/defaults";
import { DetailsIcon } from "../images/details";
import { ResultsIcon } from "../images/results";
import download from '../images/download.svg';
import moment from "moment";
import { useNavigate, useParams } from "react-router-dom";
import edit from '../images/edit.svg';
import flag from '../images/flag.svg';
import flagWhite from '../images/flag-white.svg';
import api from "../utils/api";
import { COUNTRIES } from "../files/countries";
import { NotificationManager } from "react-notifications";
import useAuth from "../utils/useAuth";

const SELECTED_COLOR = 'rgba(79, 89, 240, 1)';
const UNSELECTED_COLOR = 'rgba(91, 99, 125, 1)';

const Loader = () => <ContentLoader viewBox="0 0 150 30">
    <rect rx="4" ry="4" width="150px" height="30px" />
</ContentLoader>

const Contents = styled.div`
    padding: 30px;
`;

const TopTitle = styled.div`
    color: ${SELECTED_COLOR};
    font-size: 31px;
    margin-bottom: 23px;
    font-family: 'Satoshi-Medium'
`;

const TabTitleWrapper = styled(Row)`
    padding:16px 22px;
    align-items: center;
    cursor: pointer;
    border-bottom: ${props => props.selected ? `1px solid ${SELECTED_COLOR}` : '1px solid transparent'};
`;

const TabTitleText = styled.div`
    font-size: 17px;
    margin-left: 8px;
    color: ${props => props.selected ?  SELECTED_COLOR : UNSELECTED_COLOR };
`;

const TabTitle = ({ selected = false, Icon, title, onClick = () => {} }) => {
    return <TabTitleWrapper selected={selected} onClick={onClick}>
        <Icon fill={selected ? SELECTED_COLOR : UNSELECTED_COLOR} />
        <TabTitleText selected={selected}>{title}</TabTitleText>
    </TabTitleWrapper>
}


const TopTab = ({ setSelectedTab, selectedTab }) => {

    const sharedProps = (tabName) => ({
        onClick: () => setSelectedTab(tabName),
        selected: tabName === selectedTab,
    })

    return <Row>
        <TabTitle Icon={DetailsIcon} title={'Details'} {...sharedProps('DETAILS')} />
        <TabTitle Icon={ResultsIcon} title={'Results'} {...sharedProps('RESULTS')} />
    </Row>


}

const ItemWrapper = styled(FlexRow)`
    border-bottom: 1px solid rgba(231, 231, 233, 0.2);
    border-top: 1px solid rgba(231, 231, 233, 0.8);
    height: 72px;
        align-items: center;
`;

const ItemKey = styled.div`
    color: rgba(5, 31, 115, 1);
    font-family: 'Satoshi-Bold';
    flex: 1;
`;

const ItemFixedValue = styled.div`
    color: rgba(91, 99, 125, 1);
    font-family: 'Satoshi-Bold';
    flex: 1;
`;

const GoBack = styled.div`
    color: rgba(91, 99, 125, 1);
    font-size: 14px;
    cursor: pointer
`;

const Edit = styled.input`
    border: 1px solid rgba(219, 219, 219, 1);
    border-radius: 4px;
    padding: 11px 12px;
    font-size: 16px;
    flex: 1;
    font-family: 'Satoshi-Medium';
    outline: none;
    color: rgba(91, 99, 125, 1);

    &::placeholder {
        color: rgba(91, 99, 125, 0.3)
    }

    &:focus {
        border: 1px solid rgba(5, 31, 115, 1);;
    }
`;

const ItemValue = ({ 
    data, 
    loading, 
    type, 
    values, 
    label, 
    suffix, 
    editMode, 
    setValues,
    placeholder,
    options,
    formatter,
    editBlocked,
}) => {

    const value =  values[label] && suffix ? values[label] + suffix : values[label];

    const inputRef = useRef(null);

    useEffect(() => {
        setValues((v) => ({...v, [label]: data[label] }))
    }, [data])

    const update = (e) => {

        let newValue = Array.isArray(e) ? e : e?.target?.value;

        newValue = typeof(newValue) === 'string' ? newValue.charAt(0).toUpperCase() + newValue.slice(1) : newValue

        if (newValue?.length && newValue[0]?.label) {
            newValue = e[0].label
        }
        
        if (suffix && ((newValue?.length || 0) < value?.length)) {
            const diff = newValue - value?.length;
            newValue = value?.slice(0, diff - suffix.length)
        }
        if (suffix && newValue) newValue = newValue.replace(new RegExp(suffix, 'g'), '', )
        
        setValues((v) => ({...v, [label]: newValue}))
    }

    if (editBlocked) {
        return loading ? <div style={{ height: 30, width: 150, display: 'flex' }}><Loader /></div> :
        <ItemFixedValue>{(formatter && !!value) ? formatter(value) : !!value ? value : '---' }</ItemFixedValue>
    }

    return loading ? <div style={{ height: 30, width: 150, display: 'flex' }}><Loader /></div> :
    !loading && editMode && options? <Dropdown onChange={update} options={options} value={value}/> :
    !loading && editMode ? <Edit  placeholder={placeholder} ref={inputRef} type={type || 'text'} value={value
    }  onChange={update} /> :
    <ItemFixedValue>{(formatter && !!value) ? formatter(value) : !!value ? value : '---' }</ItemFixedValue>

}

const ItemRow = styled(Row)`
    flex: 1; 
    gap: 10px;
    
    &>* {
        flex: 1;
    }

    .react-dropdown-select-content {
        white-space: nowrap;
    }

    .react-dropdown-select-input {
        flex: 4;
    }

    .react-dropdown-select-dropdown-handle {
        flex: initial;
    }
`

const Item = ({ 
    name, 
    items,
    data,
    values,
    setValues,
    editMode,
    loading,
    editBlocked,
    ...props
}) => {

    const sharedProps = {
        data, values, setValues, name, editMode, loading, editBlocked
    }
    
    items = items?.length ? 
    items.map((i) => ({...sharedProps, ...i})) : 
    [{...sharedProps, ...props}]


    return <ItemWrapper>
        <ItemKey>{name}</ItemKey>
        <ItemRow>
            {items.map((i) => <ItemValue {...sharedProps} {...i} />)}
        </ItemRow>
    </ItemWrapper>
}

const resultsKeys = [
    { label: 'chronological_age', name: 'Chronological Age', formatter: (age) => !!age ? parseFloat(age)?.toFixed(2) : age },
    { label: 'biological_age', name: 'TruMe Age', formatter: (age) => !!age ? parseFloat(age)?.toFixed(2) : age },
    { label: 'methylation_level', name: 'Methylation Level', formatter: (level) => !!level ? parseFloat(level)?.toFixed(2) : level },
];



const SLEEP_HOURS = ['5 or less', '6', '7', '8', '9', '10+']
const YES_NO = ['Yes', 'No']
const DRINKS_PER_WEEK = ['0', '1-2', '3-5', '6-9', '10+']
const PHYSICAL_ACTIVITY_DAYS = ['0', '1', '2', '3', '4', '5', '6+']
const BIOLOGICAL_SEX_OPTIONS = ['Female', 'Male'];
const GENDER_OPTIONS = ['Male', 'Female', 'Non-Binary'];
const ETHNICITY_OPTIONS = ['White', 'Hispanic and Latino', 'Black / African American', 'Asian', 'American Indian or Alaska Native', 'Native Hawaiian or Other Pacific Islander', 'Other']
const CONDITION_OPTIONS = ['Excellent', 'Good', 'Fair', 'Poor']
const STRESS_OPTIONS = [ 'Not at all', 'Somewhat stressed', 'Really stressed', 'Extremely stressed' ]


const detailsKeys = [
    { label: 'barcode', editBlocked: true, name: 'Barcode', placeholder: '12345' },
    { label: 'email', editBlocked: true, name: 'Email', placeholder: 'mail@mail.com' },
    { label: 'registered_at', editBlocked: true, name: 'When was the sample collected?', type: 'date', formatter: (date) => moment(date).format('MM-DD-YYYY') },
    { label: 'first_name', name: 'First Name', placeholder: 'John' },
    { label: 'last_name', name: 'Last Name', placeholder: 'Doe' },
    { label: 'dob', name: 'Date of Birth', type: 'date', formatter: (date) => moment(date).format('MM-DD-YYYY') },
    { label: 'gender', name: 'Gender', options: GENDER_OPTIONS },
    { label: 'biological_sex', name: 'Biological Sex', options: BIOLOGICAL_SEX_OPTIONS},
    { label: 'country', name: 'Country', options: COUNTRIES.map((c) => c.name)},
    { label: 'ethnicity', name: 'Ethnicity', options: ETHNICITY_OPTIONS},
    { items: [
        { label: 'height_feet', name: 'Feet', number: true, suffix: ' Ft', placeholder: 'Feet'},
        { label: 'height_inches', name: 'Inches', number: true, suffix: ' In', placeholder: 'Inches'}
    ], name: 'How tall are you?', },
    { label: 'weight', name: 'What is your weight in pounds (lbs)?', suffix: ' Lbs', placeholder: 'Pounds', number: true },
    { label: 'physical_activity_days', name: 'Physical activity days', placeholder: 'Days', options: PHYSICAL_ACTIVITY_DAYS},
    { label: 'sleep_hours', name: 'Sleep hours', options: SLEEP_HOURS},
    { label: 'alcohol_consumption', name: 'Alcoholic drinks per week', options: DRINKS_PER_WEEK},
    { label: 'trouble_sleeping', name: 'Do you have trouble sleeping?', options: YES_NO},
    { label: 'waking_up_condition', name: 'Do you wake up rested?', options: YES_NO},
    { name: 'Do you drink coffee?', label: 'coffee_consumption',options: YES_NO },
    { name: 'Do you smoke?', label: 'tobacco_consumption', options: YES_NO },
    { name: 'Have you been diagnosed with cancer?', label: 'has_cancer', options: YES_NO },
    { name: 'Have you been diagnosed with diabetes?', label: 'has_diabetes', options: YES_NO }, 
    { name: 'How would you rate your mental health?', label: 'mental_health', options: CONDITION_OPTIONS},
    { name: 'Are you satisfied with your social life?', label: 'social_life', options: YES_NO },
    { name: `How stressed are you?`, label: 'stress', options: STRESS_OPTIONS },
    { name: 'What medications are you taking?',label: 'medicine_consumption', placeholder: 'Medicine list' },
    { name: 'What supplements are you taking?', label: 'supplement_consumption', placeholder: 'Supplement List' },
    { name: 'How would you rate your overall health?', label: 'overall_health', options: CONDITION_OPTIONS}

]

const ButtonFilled = styled(Button)`
    border-radius: 100px;
    padding: 10px 25px;
    height: 45px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 6px;
    opacity: ${ props => props.disabled ? 0.5 : 1};
    pointer-events: ${ props => props.disabled ? 'none' : 'initial'};
`;

const ButtonEmpty = styled(ButtonFilled)`
    border-radius: 100px;
    padding: 10px 25px;
    height: 45px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    flex-direction: row;
    background-color: transparent;
    border: 1px solid #4F59F0;
    color: #4F59F0;
    gap: 6px;
`;


const FlagContents = ({ status }) => <>
    <div>{status === 'FLAGGED' ? 'Unflag' : 'Flag'}</div>
    <img src={status === 'FLAGGED' ? flagWhite : flag} alt='Flag' />
</>

const TopButtons = ({ triggerFlag, startEdit, status, barcode, report }) => {

    const { claims } = useAuth();
    const [loading, setLoading] = useState(false)

    const FlagComponent = status === 'FLAGGED' ? ButtonFilled : ButtonEmpty;

    const downloadReport = async () => {
        setLoading(true)
        const res = await api.get(`/reports?barcode=${barcode}`);
        window.open(res.data.url, '_blank');
        setLoading(false)
    };

    const flag = async () => {
        setLoading(true);
        await triggerFlag({ barcode, status });
        setLoading(false);
    }

    return <>
        {claims?.brand === 1 && <FlagComponent loading={loading} onClick={flag}>
            <FlagContents status={status} />
        </FlagComponent>}
        <ButtonFilled onClick={startEdit}>
            <div>Edit</div>
            <img src={edit} alt='Edit' />
        </ButtonFilled>
        {status === "PROCESSED" && <ButtonFilled loading={loading} onClick={downloadReport}>
            Report
            <img src={download} onClick={downloadReport} alt='Download' />
        </ButtonFilled>}
    </>
}

const EditButtons = ({ saveDisabled, update, cancel, loading }) => {
    return <>
        <ButtonEmpty onClick={cancel}>
            <div>Cancel</div>
        </ButtonEmpty>
        <ButtonFilled loading={loading} onClick={update} disabled={saveDisabled}>
            <div>Save</div>
        </ButtonFilled>
    </>
}


const mapResults = (data) => {

    const { 
        height_inches, 
        height_feet, 
        weight 
    } = data?.health_status || {}
    
    return {
        first_name: data.user?.first_name,
        last_name: data.user?.last_name,
        scanned_at: data.scanned_at ? moment(new Date(data.scanned_at)).format('YYYY-MM-DD') : null,
        registered_at: data.registered_at ?  moment(new Date(data.registered_at)).format('YYYY-MM-DD') : null,
        dob: moment(new Date(data.demographic?.date_of_birth)).format('YYYY-MM-DD'),
        email: data.contact?.email,
        barcode: data.barcode,
        biological_age: data.health_result?.biological_age,
        chronological_age: data?.chronological_age,
        methylation_level: data.health_result?.methylation_level,
        gender: data.demographic?.gender,
        ethnicity: data.demographic?.ethnicity,
        country: data.demographic?.country,
        biological_sex: data.demographic?.biological_sex,
        weight,
        height_feet,
        height_inches,
        status: data?.status,
        ...data?.health_status,
        report: data.report, 
    }
}

const mapResultsBack = (data) =>  {
    return {
        demographic: {
            biological_sex: data.biological_sex,
            gender: data.gender,
            ethnicity: data.ethnicity,
            country: data.country,
            date_of_birth: data.dob,
        },
        user: {
            first_name: data.first_name,
            last_name: data.last_name,
        },
        contact: {
            email: data.email,
        },
        health_status: {
            weight: data.weight ? parseInt(data.weight) : null,
            height_feet: data.height_feet ? parseInt(data.height_feet) : null,
            height_inches: data.height_inches ? parseInt(data.height_inches) : null,
            physical_activity_days: data.physical_activity_days,
            sleep_hours: data.sleep_hours,
            alcohol_consumption: data.alcohol_consumption,
            trouble_sleeping: data.trouble_sleeping,
            waking_up_condition: data.waking_up_condition,
            coffee_consumption: data.coffee_consumption,
            tobacco_consumption: data.tobacco_consumption,
            has_cancer: data.has_cancer,
            mental_health: data.mental_health,
            social_life: data.social_life,
            stress: data.stress,
            medicine_consumption: data.medicine_consumption,
            supplement_consumption: data.supplement_consumption,
            overall_health: data.overall_health
        },
        results: {
            biological_age: data.biological_age ? parseFloat(data.biological_age) : null,
            chronological_age: data.chronological_age ? parseFloat(data.chronological_age) : null,
            methylation_level: data.methylation_level ? parseFloat(data.methylation_level) : null,
        },
        scanned_at: data.scanned_at,
        barcode: data.barcode,
        registered_at: data.registered_at,
    };

}

export const Kit = () => {

    const [selectedTab, setSelectedTab] = useState('DETAILS');
    const [editMode, setEditMode] = useState(false);
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(false);
    const [values, setValues] = useState({});
    const [loadingPost, setLoadingPost] = useState(false);
    const navigate = useNavigate();
    const params = useParams();
        
    const getData = async () => {
        setLoading(true);
        const result = await api.get(`/admin/kit?number=${params.id}`);
        setData(mapResults(result.data));
        setLoading(false);
    }

    const update = async () => {
        setLoadingPost(true);
        await api.post(`/admin/kit`, mapResultsBack(values));
        setData(values);
        setEditMode(false);
        setLoadingPost(false);
    }

    useEffect(() => {
        setEditMode(false)
    }, [selectedTab])

    useEffect(() => {
        getData()
    }, [])

    const startEdit = () => {
        setEditMode(true)
    }

    const cancel = () => {
        setValues(data)
        setEditMode(false)
    }

    const sharedProps = {
        values,
        setValues,
        editMode,
        startEdit,
        loading
    }

    const triggerFlag = async ({ barcode, status }) => {
        await api.post('/admin/flag', { barcode })
        NotificationManager.success(status === 'FLAGGED' ? 'Kit was unflagged.' : 'Kit was flagged for review.') 
        await getData();
    }
            
    return <Contents>
        <GoBack onClick={() => navigate(-1)}>Go back</GoBack>
        <Row style={{ gap: 10 }}>
            <TopTitle>Patient File</TopTitle>
            <div style={{ flex: 1 }} />
            {editMode && !loading ? 
            <EditButtons loading={loadingPost} update={update} cancel={cancel} setEditMode={setEditMode}/> : 
            !loading ? <TopButtons 
                triggerFlag={triggerFlag}
                barcode={params.id}
                status={data['status']}
                startEdit={startEdit} 
                report={data?.report}
                setEditMode={setEditMode} /> : <div />}
        </Row>
        <TopTab selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
        { selectedTab === 'DETAILS' && detailsKeys.map((k) => <Item {...sharedProps} {...k} data={data} />) }
        { selectedTab === 'RESULTS' && resultsKeys.map((k) => <Item {...sharedProps} {...k} data={data} />) }
    </Contents>

}